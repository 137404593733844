import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  projectBox,
  projectBoxReverse,
  projectBox__imageBox,
  projectBox__image,
  projectBox__textBox,
  projectBox__textBox__Reverse,
  projectBox__title,
  projectBox__link,
} from "../sass/professional-projects.module.scss"

const ProjectBox = ({ companyName, description, image, link, index }) => (
  <div className={index % 2 === 0 ? projectBox : projectBoxReverse}>
    <div className={projectBox__imageBox}>
      {image && (
        <Img
          className={projectBox__image}
          alt={companyName}
          fluid={image.fluid}
        />
      )}
      {link && (
        <a
          className={projectBox__link}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`Learn more about ${companyName}`}
        </a>
      )}
    </div>

    <div
      className={
        index % 2 === 0 ? projectBox__textBox : projectBox__textBox__Reverse
      }
    >
      <div className={projectBox__title}>{companyName}</div>
      <p>{description.description}</p>
    </div>
  </div>
)

const ProfessionalProjects = ({ data: allContentfulProfessionalProjects }) => {
  const {
    allContentfulProfessionalProjects: { edges },
  } = allContentfulProfessionalProjects

  const { projects } = edges[0].node

  const seoDescription = `See the work that he did for ${projects
    .map(p => p.companyName)
    .join(", ")}.`

  return (
    <Layout>
      <SEO
        title="Guillaume Bouffard | Professional projects"
        description={seoDescription}
      />

      {projects.map((project, i) => (
        <ProjectBox key={i} {...project} index={i} />
      ))}
    </Layout>
  )
}

export default ProfessionalProjects

export const query = graphql`
  query ProfessionalProjectsQuery {
    allContentfulProfessionalProjects {
      edges {
        node {
          projects {
            companyName
            image {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            description {
              description
            }
            link
          }
        }
      }
    }
  }
`
